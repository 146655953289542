// Modules exported here will be included in bundle.js
export function showResultActionSF(resultAction, eventType, card) {

var card = {"entityType": entityType, "eventType": eventType, "isDirectAnswer": false, "title": title, "value": value};
 console.log("Card object passed:", card);

 console.log("Card object passed:", card);

//Posts a message to the Salesforce Agent Desktop app with the entire result card
 parent.postMessage(card, "*");

//The below code is the same as the result action function for Zendesk and Freshdesk.

//Gets the result action option text class, makes it hidden, and sets it as a variable.
  var optionText = resultAction.getElementsByClassName("copyMenu-copy-option")[0];
  optionText.classList.add('not-visible');
  optionText.classList.remove('made-visible');

//Gets the result action execution message class, makes it visible, and sets it as a variable.
  var executedMessage = resultAction.getElementsByClassName("copyMenu-copied-message")[0];
  executedMessage.classList.add('made-visible');

//Two seconds after the result action is executed, return the option text and execution message text to their original states. 
  setTimeout(function() { 
    optionText.classList.add('made-visible');
    optionText.classList.remove('not-visible');
    executedMessage.classList.remove('made-visible');
    }, 2000);
};

export function showCopiedMessageDirect(button, eventType, title, value) {
  var card = {"eventType": eventType, "isDirectAnswer": true, "title": title, "value": value};
  parent.postMessage(card, "*");

  var optionText = button.getElementsByClassName("copyMenu-copy-option")[0];
  optionText.classList.add('not-visible');
  optionText.classList.remove('made-visible');
  var copiedText = button.getElementsByClassName("copyMenu-copied-message")[0];
  copiedText.classList.add('made-visible');
  setTimeout(function() { 
    optionText.classList.add('made-visible');
    optionText.classList.remove('not-visible');
    copiedText.classList.remove('made-visible');
    }, 2000);
};

export function showResultActionSFDirect(resultAction, eventType, title, value, entityType) {
 
//Creates a card variable with properties eventType, isDirectAnswer, title, and value. 
 var card = {"entityType": entityType, "eventType": eventType, "isDirectAnswer": true, "title": title, "value": value};
 console.log("Card object passed:", card);

//Posts a message to the Salesforce Agent Desktop app with the entire result card variable
 parent.postMessage(card, "*");
//The below code is the same as the result action function for Zendesk and Freshdesk.

if (!resultAction) {
    console.error('resultAction is undefined');
    return;
  }

//Gets the result action option text class, makes it hidden, and sets it as a variable.
  var optionText = resultAction.getElementsByClassName("copyMenu-copy-option")[0];
  if (optionText) {
    optionText.classList.add('not-visible');
    optionText.classList.remove('made-visible');
  } else {
    console.error('copyMenu-copy-option element not found');
  }

//Gets the result action execution message class, makes it visible, and sets it as a variable.
  var executedMessage = resultAction.getElementsByClassName("copyMenu-copied-message")[0];
  if (executedMessage) {
    executedMessage.classList.add('made-visible');
  } else {
    console.error('copyMenu-copied-message element not found');
  }

//Two seconds after the result action is executed, return the option text and execution message text to their original states. 
  setTimeout(function() {
    if (optionText) {
      optionText.classList.add('made-visible');
      optionText.classList.remove('not-visible');
    }
    if (executedMessage) {
      executedMessage.classList.remove('made-visible');
    }
  }, 2000);
};

export function showClipboardContent(resultAction, copyData) {

//Create a ‘clipboardData’ object that will hold the data to be copied to the clipboard by initializing the ‘text/html’ property with a ‘Blob’ object containing the ‘copyData’ as HTML content.
 const clipboardData = {
   'text/html': new Blob([copyData], {type: 'text/html'})
 };

//Add a ‘text/plain’ property to the ‘clipboardData’ object by initializing the ‘text/plain’ property with a ‘Blob’ object that contains the ‘copyData’ as plain text. Reference the ‘convertToPlainText’ function at the bottom of the code to generate a plain text version of the html data. 
 clipboardData['text/plain'] = new Blob([convertToPlainText(copyData)], {type: 'text/plain'});

//Create a new ‘ClipboardItem’ object named ‘clipboardItem’ that contains the ‘clipboardData’ object. Write the ‘clipboardItem’ to the clipboard using the ‘navigator.clipboard.write’ method. 
 const clipboardItem = new ClipboardItem(clipboardData);
 navigator.clipboard.write([clipboardItem]);

//The below code is the same as the result action functions for Zendesk / Freshdesk and Salesforce.

//Gets the result action option text class, makes it hidden, and sets it as a variable.
  var optionText = resultAction.getElementsByClassName("copyMenu-copy-option")[0];
  optionText.classList.add('not-visible');
  optionText.classList.remove('made-visible');

//Gets the result action execution message class, makes it visible, and sets it as a variable.
  var executedMessage = resultAction.getElementsByClassName("copyMenu-copied-message")[0];
  executedMessage.classList.add('made-visible');

//Two seconds after the result action is executed, return the option text and execution message text to their original states. 
  setTimeout(function() { 
    optionText.classList.add('made-visible');
    optionText.classList.remove('not-visible');
    executedMessage.classList.remove('made-visible');
    }, 2000);
};
function convertToPlainText(html){
   let doc = new DOMParser().parseFromString(html, 'text/html');
   return doc.body.textContent || "";
}


